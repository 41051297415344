'use client';
import React from 'react';
import Rselect from 'react-select';
import { Text } from '@/components/Text';

interface SelectProps extends React.HTMLProps<HTMLDivElement> {
    id?: string;
    label?: string;
    defaultValue?: string;
    error?: string;
    options: { value: string; label: string }[];
    onUpdate: (value: string) => void;
}

export const Select = (props: SelectProps) => {
    const { id, label, defaultValue, error, options, onUpdate, className } = props;
    const idToUse = id || label?.toLowerCase().replace(' ', '-');

    return (
        <div className={className}>
            {label && (
                <Text variant="label" htmlFor={idToUse}>
                    {label}
                </Text>
            )}
            <Rselect
                defaultValue={options.find((option) => option.value === defaultValue)}
                options={options}
                isSearchable={false}
                className="mt-1"
                onChange={(option) => {
                    if (option) {
                        onUpdate(option.value);
                    }
                }}
                styles={{
                    control: (styles, state) => ({
                        ...styles,
                        border: state.isFocused ? '0px solid #3B9167' : '1px solid #3B9167',
                        borderRadius: '0.375rem',
                        padding: '0.25rem',
                        '&:hover': {
                            borderColor: '#3B9167',
                        },
                        fontFamily: '__Nunito_9a18e5',
                        fontSize: '1rem',
                    }),
                    option: (styles, { isFocused, isSelected }) => ({
                        ...styles,
                        backgroundColor: isSelected ? '#3B9167' : isFocused ? '#3B916720' : 'transparent',
                        fontSize: '1rem',
                        fontFamily: '__Nunito_9a18e5',
                    }),
                    indicatorSeparator: (styles) => ({
                        ...styles,
                        display: 'none',
                    }),
                    valueContainer: (styles) => ({
                        ...styles,
                        bgcolor: 'red',
                        fontFamily: '__Nunito_9a18e5',
                        fontSize: '1rem',
                    }),
                }}
            />
            {error && <Text fontColor="error">{error}</Text>}
        </div>
    );
};
